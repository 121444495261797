
















import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  ListingStatus, Office, Profile, WebsiteLevel, MapSettings, WebsiteProfileDisplayType, ListingCategory, MethodOfSale,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';

import Listings from '@/components/Listings.vue';
import Loader from '@/components/UI/Loader.vue';
import FranchiseListingSearch from '@/components/FranchiseListingSearch.vue';

@Component({
  components: {
    Listings,
    Loader,
    FranchiseListingSearch,
  },
})
export default class ListingSearch extends Mixins(AsyncView) {
  private listingFilter: ListingFilter | null = null;

  get getMos(): string {
    return this.$route.fullPath.replace('/listings/', '');
  }

  public head(): RouteMeta {
    return {
      title: this.getMos,
      description: 'Explore our Properties',
      // image: this.listing!.Images.length ? this.listing!.Images[0].Preview.Url : undefined,
    };
  }

  gotFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
